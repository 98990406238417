import i18n from '@/i18n'

export const commonOptions_hmn = {

    data() {
        return { 

            hmn: {
            
                sexOptions: [
                    { value: 'M', text: 'Txivneej' },
                    { value: 'F', text: 'Pojniam' },
                    { value: 'O', text: 'Transgender' },
                    { value: 'O', text: 'Non-Binary' },
                    { value: 'U', text: 'Xum Tsis Teb' },
                ],

                lgbtOptions: [
                    { value: '1', text: 'Straight' },
                    { value: '2', text: 'Gay or Lesbian' },
                    { value: '4', text: 'Bisexual' },
                    { value: '5', text: 'I don\'t know' },
                    { value: '6', text: 'Something else' },
                    { value: '9', text: 'Prefer not to say' },
                ],

                raceOptions: [
                    { value: 'W', text: 'Tawv Dawb' },
                    { value: 'B', text: 'Tawv Dub' },
                    { value: 'A', text: 'Esxias' },
                    { value: 'P', text: 'Neeg Nyob Hawaii Ib Txwm/Nyob Cov Pov Txwv Pacific' },
                    { value: 'I', text: 'TODO' },
                    { value: 'O', text: 'Lwm Yam' },
                    { value: 'U', text: 'Tsis Paub' },
                ],

                livingOptions: [
                    { value: '1', text: 'Private Residence' },
                    { value: '11', text: 'Xaub Chav Pw' },
                    { value: '2', text: 'Tsev tu neeg mob mus sijhawm ntev, Tsev kho neeg mob ib ce lossis siab ntsws lossis Tsev pab tu neeg laus' },
                    { value: '9', text: 'Tsev rau ib pab pawg nyob ua ke lossis tsev kho cwj pwm' },
                    { value: '5', text: 'Tsev pab rau cov neeg tsis muaj tsev nyob' },
                    { value: '12', text: 'Tsis muaj tsev nyob' },
                    { value: '6', text: 'Nyob hauv tsev loj cum' },
                    { value: '7', text: 'Tsev Qeb Siab/Tsev nyob tsev kawm ntawv' },
                    { value: '10', text: 'Tsev nyob tom chaw haujlwm' },
                    { value: '8', text: 'Lwm yam' },
                ],

                contactSettingOptions: [
                    { value: 'H', text: 'Tsev kho mob' },
                    { value: 'N', text: 'Tsev tu neeg laus, tu neeg mob mus sijhawm ntev, kho neeg mob ib ce lossis siab ntsws, lossis lwm yam li no' },
                    { value: 'G', text: 'Tsev rau ib pab pawg nyob ua ke lossis lwm yam li no' },
                    { value: 'C', text: 'Tsev loj cum' },
                    { value: 'S', text: 'Tsev rau cov neeg muaj tsev chaw nyob' }
                ],

                eventOptions: [
                    { value: '10', text: 'Khw ua mov noj lossis tsev haus dej cawv ' },
                    { value: '11', text: 'Chaw txiav plaub hau lossis spa' },
                    { value: '12', text: 'Tsev nqa kab lossis tsev ua ev xaws xais' },
                    { value: '13', text: 'Mus ncaws pob (mus koom kiag lossis mus saib)' },
                    { value: '1', text: 'Mus teev ntuj thov vajtswv' },
                    { value: '2', text: 'Mus koom tej koob tsheej (festival lossis fair)' },
                    { value: '3', text: 'Mus koom tej koob tsheej ncaws pob' },
                    { value: '4', text: 'Mus koom tsev neeg ua noj tom tsev lossis party' },
                    { value: '5', text: 'Mus mloog neeg ntaus nkauj hu nkauj lom zem' },
                    { value: '6', text: 'Mus noj tshoob' },
                    { value: '7', text: 'Mus koom kev pam tuag' },
                    { value: '8', text: 'Mus koom kev ua party hauv cheeb tsam koj nyob' },
                    { value: '9', text: 'Lwm yam' }
                ],

                yesNoOptions: [
                    { value: 'Y', text: 'Yog' },
                    { value: 'N', text: 'Tsis yog' },            
                ],

                symptomYesNoOptions: [
                    { value: 'Y', text: 'Yog, kuv tau muaj tej tsos mob' },
                    { value: 'N', text: 'Tsis yog, kuv tsis tau muaj tej tsos mob' },            
                ],

                yesNoUnknownOptions: [
                    { value: 'Y', text: 'Yog' },
                    { value: 'N', text: 'Tsis yog' },
                    { value: 'U', text: "Tsis paub" },            
                ],

                yesNoUnknownNaOptions: [
                    { value: 'Y', text: 'Yog' },
                    { value: 'N', text: 'Tsis yog' },
                    { value: 'U', text: "Tsis paub" },
                    { value: 'X', text: "Tsis muaj feem" },            
                ],

                covid19Symptoms: [
                    'Ua npaws',
                    'Hnoos',
                    'Txoj siav',
                    'Saj tsis hnov qab lossis tsis hnov ntxhiab tsw',
                    'Qaug zog',
                    'Mob taubhau',
                    'Mob leeg nqaij',
                    'Raws plab',
                    'Xeev siab lossis ntuav'
                ],

                workplaceSettings: [
                    { value: 'HEALTHCARE', text: 'Tsev kho mob loj lossis tsev kuaj mob' },
                    { value: 'LTC', text: 'Tsev tu neeg mob mus sijhawm ntev, tsev pab tu neeg, lossis tsev laus' },
                    { value: 'RES_TREAT', text: 'Tsev pab neeg thum yeeb tshuaj' },
                    { value: 'GROUP_HOME', text: 'Tsev rau ib pab pawg neeg nyob ua ke'},
                    { value: 'CORRECTIONS', text: 'Tsev loj cum'},
                    { value: 'CHILDCARE', text: 'Tsev zov menyuam'},
                    { value: 'K12', text: 'Tsev kawm ntawv'},
                    { value: 'COLLEGE_UNIV', text: 'Tsev kawm ntawv qeb siab'},
                    { value: 'SPORTS', text: 'Pab pawg ncaws pob'},
                    { value: 'SHELTER', text: 'Shelter'},
                    { value: 'OTHER', text: 'Lwm yam'}
                ],

                hcwRoleOptions: [
                    { value: '1', text: 'Kws kho mob'},
                    { value: '2', text: 'Kws tu mob'},
                    { value: '6', text: 'CNA (Certified Nursing Assistant, tus kawm tiav los pab tu neeg mob)'},
                    { value: '7', text: 'PCA (Patient Care Assistant, tus tu neeg mob)'},
                    { value: '3', text: 'Kws Pab Kho Neeg Ua Pa'},
                    { value: '4', text: 'Kws tu vaj tu tsev'},
                    { value: '5', text: 'Lwm yam'},
                ],

                classTypeOptions: [
                    { value: 'NO', text: 'Kuv tsis mus kawm hauv chav kawm ntawv'},
                    { value: 'ONLINE', text: 'Kawm hauv online'},
                    { value: 'INPERSON', text: 'Mus kawm tim ntsej tim muag'},
                    { value: 'BOTH', text: 'Ob yam tibsi'},
                ], 

                campusLivingOptions: [
                    { value: 'NO', text: 'Not living in on campus housing'},
                    { value: 'DORM', text: 'Tsev koom nrog lwm tus neeg kawm ntawv nyob'},
                    { value: 'APARTMENT', text: 'Tsev nyob hauv tsev kawm ntawv qeb siab'},
                    { value: 'GREEK', text: 'Pab pawg neeg nyiam ib yam twg'},
                    { value: 'OTHER', text: 'Lwm yam tsev nyob hauv tsev kawm ntawv'},
                ],

                campusActivityOptions: [
                    { value: 'NO', text: 'Tsis nyob tom tsev kawm ntawv thaum muaj kab mob'},
                    { value: 'WORK', text: 'Ua haujlwm tom tsev kawm ntawv'},
                    { value: 'FACILITIES', text: 'Siv tej chav hauv tsev kawm ntawv (xws li chav nyeem ntawv, gym, chav no mov, lwm yam.)'},
                    { value: 'EVENTS', text: 'Mus koom tej koob tsheej tom tsev kawm ntawv, rooj sib ntsib, lossis lwm yam kis las'},
                    { value: 'HOUSING', text: 'Tau mus nyob hauv tsev kawm ntawv tej tsev (xws li mus xyuas phoojywg tom koj chav lossis nws chav)'},
                ],

                essentialItemOptions: [
                    { value: 'FOOD', text: 'Zaub Mov'},
                    { value: 'MED', text: '•Tshuaj Noj'},
                    { value: 'PLACE', text: 'Chaw nyob kom nrug deb tau ntawm lwm tus'},
                    { value: 'OTHER', text: 'Lwm yam'},
                ]
            }
        }
    },
}