import axios from "axios";
import router from "@/router";


export default {
  install: function (Vue, params = {}) {
        
    // Add a request interceptor
    axios.interceptors.request.use(
        async config => {
            config.headers['Content-Type'] = 'application/json';

            if (config.method === "get" && config.url.includes("/events")) {
                return config; 
            }

            if (config.method === "post" && config.url.includes("/registration")) {
                return config; 
            }

            if (config.method === "post" && config.url.includes("/statusChanged")) {
                return config;
            }

            if (config.method === "post" && config.url.includes("/token")) {
                return config; 
            }

            if (config.method === "get" && config.url.includes("/domain")) {
                return config; 
            }

            if (config.url.includes("/timeout") || config.url.includes("/index")
            || config.url.includes("/welcome")) {
                // this endpoint is public and does not require a jwt
                return config;
            }
            return config;
        },
        error => {
            Promise.reject(error)
        });
    
    }
}
