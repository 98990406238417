<template>
  <div id="app">
    <header>
      <div id="nav">
        <b-navbar toggleable="lg" type="light" variant="mdh">
          <b-navbar-brand href="/">
            <img src="./assets/logo-reverse.png" style="height:38px;" alt="Minnesota Department of Health Logo"/>
          </b-navbar-brand>
          <b-navbar-toggle target="nav-collapse" class="navbar-dark"></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
               
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </div>
    </header>

    <main id="main-content">
      <b-container fluid>
        <router-view/>
      </b-container>
    </main>

<div class="mt-5">
  <footer>
    <hr class="mt-2"/>
    <div class="fluid-container footer">
      <p class="text-center">For questions, please email <a :href="`mailto:${contactEmail}`">{{contactEmail}}</a>  - v{{appVersion}}</p>
    </div>
  </footer>
</div>

  </div>
</template>

<script>
  import {version} from '../package.json';
  import { CONTACT_EMAIL_ADDRESS } from '@/constants'
  import {commonOptions} from "@/mixins/commonOptions";

  export default {
    metaInfo: {
      title: 'Welcome',
      titleTemplate: '%s - COVID-19 Interview',
      htmlAttrs: {
        lang: 'en',
        amp: true
      }
    },

    mixins: [
      commonOptions
    ],

    data: () => ({
      preferredLanguage: '',
    }),

    computed: {
      authenticated: function() {
        if (this.$keycloak) {
          return this.$keycloak.authenticated;
        } else {
          return false;
        }
      },
      appVersion: function() {
        return version;
      },
      contactEmail: function() {
        return CONTACT_EMAIL_ADDRESS
      },
    },

    watch: {
      preferredLanguage() {
        this.$i18n.locale = this.preferredLanguage;
        this.$root.$i18n.locale = this.preferredLanguage;
      },
    },

    methods: {
      login () {
      },

      logout() {
      },
      
      hasRole(roles) {
        return false;
      }
    },
  }
</script>

<style lang="scss">

  @import "assets/_custom.scss";
  @import "~bootstrap/scss/bootstrap.scss";
  @import '~bootstrap-vue/dist/bootstrap-vue.css';
  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
  @import url('https://rawgit.com/lykmapipo/themify-icons/master/css/themify-icons.css');

#app {
  font-family: 'Roboto', 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

h1 {
  font-size: 30px;
  font-weight: 600;
}

h2 {
  font-size: 24px;
  font-weight: 600;
}

button {
  margin-right: 18px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

.bg-mdh {
  background-color: rgb(0, 56, 101);
}

#nav {
  padding-bottom: 30px;
}

#nav a {
  font-weight: normal;
  color: white;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

#nav  a.dropdown-item {
  color: black !important;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

.colWidth40 {
  width: 40%;
}

.is-invalid .d-block {
    color: #ef0000;
}

.label-required::after {
  color: #0a8927;
  font-size: .7em;
  text-transform: uppercase;
  margin-left: 19px;
  content:" required";
}

.sectionbreak {
  display: block;
  font-weight: 700;
  font-size: 1.1em;
}

.wizard-card-footer div span {
  outline: none;
}

.wizard-card-footer div span:focus button {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(155, 89, 182, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(155, 89, 182, 0.25);
}

.wizard-nav li:has(.disabled) {
  user-focus: none;
 }

.vue-form-wizard .wizard-nav-pills>li>a {
    color: rgba(0,0,0,.4);
}

.stepTitle {
  color: #53565a;
}
.wizard-icon {
  color: #53565a;
}
</style>
