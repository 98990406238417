import i18n from '@/i18n'

export const commonOptions_so = {

    data() {
        return { 

            so: {
            
                sexOptions: [
                    { value: 'M', text: 'Nin' },
                    { value: 'F', text: 'Naag' },
                    { value: 'O', text: 'Transgender' },
                    { value: 'O', text: 'Non-Binary' },
                    { value: 'U', text: 'Waxaan durbidiyaa in aanan sheegin' },
                ],

                lgbtOptions: [
                    { value: '1', text: 'Straight' },
                    { value: '2', text: 'Gay or Lesbian' },
                    { value: '4', text: 'Bisexual' },
                    { value: '5', text: 'I don\'t know' },
                    { value: '6', text: 'Something else' },
                    { value: '9', text: 'Prefer not to say' },
                ],

                raceOptions: [
                    { value: 'W', text: 'Caddaan' },
                    { value: 'B', text: 'Madow' },
                    { value: 'A', text: 'Aasiyaan' },
                    { value: 'P', text: 'Dhaladka Hawaii / Jasiiradda Baasifigga' },
                    { value: 'I', text: 'TODO' },
                    { value: 'O', text: 'Kuwo kale' },
                    { value: 'U', text: 'Lama yaqaan' },
                ],

                livingOptions: [
                    { value: '1', text: 'Private Residence' },
                    { value: '11', text: 'Huteel/Albeergo' },
                    { value: '2', text: 'Xarunta daryeelka muddada-dheer, Xarunta  Dhaqan-celinta ama xarunta ku-noolaanshaha ee Caawinta' },
                    { value: '9', text: 'Guryo kooxeed ama xarun caafimaadka hab dhaqameed' },
                    { value: '5', text: "Hooy loogu talagalay dadka ay guri la'aanta haysato" },
                    { value: '12', text: "Waqtigaan la joogo hoy ma leh waxaana la soo deristay guri la'aan" },
                    { value: '6', text: 'Jeel/Xabsi' },
                    { value: '7', text: 'Kuleej/Jaamacad/hoyga jiifka' },
                    { value: '10', text: 'Hoyga shaqada' },
                    { value: '8', text: 'Kuwo kale' },
                ],

                contactSettingOptions: [
                    { value: 'H', text: 'Xarunta daryeelka caafimaadka' },
                    { value: 'N', text: 'Hoyga daryeelka, daryeelka muddada-dheer, xarunta dhaqan-celinta, ama xarun la mid ah' },
                    { value: 'G', text: 'Guryo kooxeed ama nooc la mid ah' },
                    { value: 'C', text: 'Xarunta asluubta' },
                    { value: 'S', text: "Hoyga loogu talagalay dadka ay hoy la'aanta haysata" }
                ],

                eventOptions: [
                    { value: '10', text: 'Makhaayadaha ama baararka' },
                    { value: '11', text: 'Saloonno (Salons) ama isbaa (spas)' },
                    { value: '12', text: 'Jiimka ama xarumaha adadkaanta (fitness centers)' },
                    { value: '13', text: 'Isboortiga (ka qaybqaadasho ama ka qaybgal dhacdooyin) ' },
                    { value: '1', text: 'Kulan diineed' },
                    { value: '2', text: 'Fantasiye ama carwo' },
                    { value: '3', text: 'Dhacdo ciyaaro' },
                    { value: '4', text: 'Kulan qoys/bulsheed ama xaflado' },
                    { value: '5', text: 'Kulan muusik ama konsert' },
                    { value: '6', text: 'Aroos' },
                    { value: '7', text: 'Aas/axan' },
                    { value: '8', text: 'Xaflad sakad xaafad' },
                    { value: '9', text: 'Ku kale' }
                ],

                yesNoOptions: [
                    { value: 'Y', text: 'Haa' },
                    { value: 'N', text: 'Maya' },            
                ],

                symptomYesNoOptions: [
                    { value: 'Y', text: 'Haa, calaamado ayaan lahaa' },
                    { value: 'N', text: 'No, ma aan lahayn calaamado' },            
                ],

                yesNoUnknownOptions: [
                    { value: 'Y', text: 'Haa' },
                    { value: 'N', text: 'Maya' },
                    { value: 'U', text: "Lama yaqaano" },            
                ],

                yesNoUnknownNaOptions: [
                    { value: 'Y', text: 'Haa' },
                    { value: 'N', text: 'Maya' },
                    { value: 'U', text: "Ma aqaano" },
                    { value: 'X', text: "Ma khuseyso" },            
                ],

                covid19Symptoms: [
                    'Qandho',
                    'Qufac',
                    'Dhib ku qaba neefsashda',
                    'Lumida dareenka dhadhanka ama urta',
                    'Daal',
                    'Madax xanuun',
                    'Murqo xanuun',
                    'Shuban',
                    'Lalabbo ama matagid'
                ],

                workplaceSettings: [
                    { value: 'HEALTHCARE', text: 'Xaruunta daryeelka caafimaadka sida isbitaal ama rug caafimaad' },
                    { value: 'LTC', text: 'Daryeelka muddada-dheer, daryeelka nool gacanqabashada, ama guriga daryeelka caafimaadka' },
                    { value: 'RES_TREAT', text: 'Ku xadgudubka maandooriyaha iyo barnaamijka bukaan-jiif daaweynta' },
                    { value: 'GROUP_HOME', text: 'Guri kooxeed'},
                    { value: 'CORRECTIONS', text: 'Xarunta asluubta sida xabsi ama jeel'},
                    { value: 'CHILDCARE', text: 'Xarunta daryeelka ilmaha ama daryeelka carruurta ee guriga dhexdiisa ah'},
                    { value: 'K12', text: 'Iskuulka'},
                    { value: 'COLLEGE_UNIV', text: 'Kuleejo, jaamacad ama machad kale oo tacliinta sare ah'},
                    { value: 'SPORTS', text: 'Isboorti abaabulan'},
                    { value: 'SHELTER', text: 'Shelter'},
                    { value: 'OTHER', text: 'Kuwo Kale'}
                ],

                hcwRoleOptions: [
                    { value: '1', text: 'Dhakhtar'},
                    { value: '2', text: 'Kalkaaliye caafimaad'},
                    { value: '6', text: 'CNA (Kalkaaliye Caafimaad Shahaado Leh)'},
                    { value: '7', text: 'PCA (Kaaliyaha Daryeelka Bukaanka)'},
                    { value: '3', text: 'Dabiibaha Neefsashada'},
                    { value: '4', text: "Adeegyada Bii'ada (tusaale, nadaafadda guriga, shaqaalaha nadaafadda)"},
                    { value: '5', text: 'Kuwo kale'},
                ],

                classTypeOptions: [
                    { value: 'NO', text: 'Kama xaadiro fasallada'},
                    { value: 'ONLINE', text: 'Khadka tooska ah'},
                    { value: 'INPERSON', text: 'Qof ahaan'},
                    { value: 'BOTH', text: 'Labadaba'},
                ], 

                campusLivingOptions: [
                    { value: 'NO', text: 'Not living in on campus housing'},
                    { value: 'DORM', text: 'Doormaha'},
                    { value: 'APARTMENT', text: 'Abaarmannda xerada tacliinta'},
                    { value: 'GREEK', text: 'Ururkawiilasha jaamacadda/ Ururka gabdhaha jaamacadda'},
                    { value: 'OTHER', text: 'Guryiyaynta kale ee xerada'},
                ],

                campusActivityOptions: [
                    { value: 'NO', text: 'Waqti kuma qaadan xerada markaan bukay'},
                    { value: 'WORK', text: 'Xerada ayaan ka shaqaynayay'},
                    { value: 'FACILITIES', text: 'Waxaan adeegsaday wixii kasta ee tas-hiilaad xerada ah (sida maktabadda, qolka jimicsiga, maqaaxi, iwm)'},
                    { value: 'EVENTS', text: 'Kaqeybgalay munaasabado, shirar, ama howlo kasta oo ay ka dambayso jaamacaddu, '},
                    { value: 'HOUSING', text: 'Waqti ku qaatay qolalka jiifka / ururka walaalaha / guryiyanta ku yaal xerada (oo ay ku jiraan booqashada asxaabta ama xerooyinka kale ee tacliinta)'},
                ],

                essentialItemOptions: [
                    { value: 'FOOD', text: 'Cunto'},
                    { value: 'MED', text: 'Dawo'},
                    { value: 'PLACE', text: 'Meel ammaan ah oo aad ka fogaan uga joogto dadka kale'},
                    { value: 'OTHER', text: 'Kuwo kale'}
                ]
            }
        }
    },
}