import i18n from '@/i18n'

export const commonOptions_es = {

    data() {
        return { 

            es: {
            
                sexOptions: [
                    { value: 'M', text: 'Varón' },
                    { value: 'F', text: 'Mujer' },
                    { value: 'O', text: 'Transgender' },
                    { value: 'O', text: 'Non-Binary' },
                    { value: 'U', text: 'Prefiere no divulgar' },
                ],

                lgbtOptions: [
                    { value: '1', text: 'Straight' },
                    { value: '2', text: 'Gay or Lesbian' },
                    { value: '4', text: 'Bisexual' },
                    { value: '5', text: 'I don\'t know' },
                    { value: '6', text: 'Something else' },
                    { value: '9', text: 'Prefer not to say' },
                ],

                raceOptions: [
                    { value: 'W', text: 'Blanca' },
                    { value: 'B', text: 'Negra' },
                    { value: 'A', text: 'Asiática' },
                    { value: 'P', text: 'Nativa hawaiana/isleña del Pacífico' },
                    { value: 'I', text: 'Indio Americano Nativo de Alaska /' },
                    { value: 'O', text: 'Otra' },
                    { value: 'U', text: 'Desconocida' },
                ],

                livingOptions: [
                    { value: '1', text: 'Residencia privada' },
                    { value: '11', text: 'Hotel / motel' },
                    { value: '2', text: 'Centro de cuidado a largo plazo, centro de rehabilitación o establecimiento de vida asistida' },
                    { value: '9', text: 'Vivienda grupal o centro de salud conductual' },
                    { value: '5', text: 'Un refugio para personas sin hogar' },
                    { value: '12', text: 'Actualmente sin refugio y sin hogar' },
                    { value: '6', text: 'Cárcel/Prisión' },
                    { value: '7', text: 'Dormitorio de campus/colegio/universidad' },
                    { value: '10', text: 'Dormitorio de trabajo' },
                    { value: '8', text: 'Otro' },
                ],

                contactSettingOptions: [
                    { value: 'H', text: 'Centro de atención médica' },
                    { value: 'N', text: 'Hogares de ancianos, centro de cuidado a largo plazo, centro de rehabilitación o instalación de tipo similar' },
                    { value: 'G', text: 'Vivienda grupal o instalación de tipo similar' },
                    { value: 'C', text: 'Centro correccional' },
                    { value: 'S', text: 'Refugio para personas sin hogar' }
                ],

                eventOptions: [
                    { value: '10', text: 'Restaurantes o bares' },
                    { value: '11', text: 'Peluquerías o centros de belleza integral (spa)' },
                    { value: '12', text: 'Gimnasios o centros de acondicionamiento físico' },
                    { value: '13', text: 'Deportes (como deportista o espectador)' },
                    { value: '1', text: 'Servicio religioso' },
                    { value: '2', text: 'Festival o feria' },
                    { value: '3', text: 'Evento deportivo' },
                    { value: '4', text: 'Reuniones familiares o sociales o fiestas' },
                    { value: '5', text: 'Conciertos' },
                    { value: '6', text: 'Bodas' },
                    { value: '7', text: 'Funeral/velatorios' },
                    { value: '8', text: 'Fiesta en la calle' },
                    { value: '9', text: 'Otros' }
                ],

                yesNoOptions: [
                    { value: 'Y', text: 'Sí' },
                    { value: 'N', text: 'No' },            
                ],

                symptomYesNoOptions: [
                    { value: 'Y', text: 'Sí, he tenido síntomas' },
                    { value: 'N', text: 'No, no he tenido ningún síntoma' },            
                ],

                yesNoUnknownOptions: [
                    { value: 'Y', text: 'Sí' },
                    { value: 'N', text: 'No' },
                    { value: 'U', text: "No lo sé" },            
                ],

                yesNoUnknownNaOptions: [
                    { value: 'Y', text: 'Sí' },
                    { value: 'N', text: 'No' },
                    { value: 'U', text: "No lo sé" },
                    { value: 'X', text: "No aplica" },            
                ],

                covid19Symptoms: [
                    'Fiebre',
                    'Tos',
                    'Dificultad para respirar',
                    'Pérdida del sentido del gusto o del olfato',
                    'Fatiga',
                    'Dolor de cabeza',
                    'Dolores musculares',
                    'Diarrea',
                    'Náuseas o vómitos'
                ],

                workplaceSettings: [
                    { value: 'HEALTHCARE', text: 'Entorno de atención médica como un hospital o una clínica' },
                    { value: 'LTC', text: 'Cuidado a largo plazo, vida asistida u hogar de ancianos' },
                    { value: 'RES_TREAT', text: 'Programa de tratamiento para pacientes hospitalizados por abuso de sustancias' },
                    { value: 'GROUP_HOME', text: 'Vivienda grupal'},
                    { value: 'CORRECTIONS', text: 'Centro correccional, como una prisión o cárcel'},
                    { value: 'CHILDCARE', text: 'Centro de cuidado infantil o cuidado infantil en el hogar'},
                    { value: 'K12', text: 'Escuela'},
                    { value: 'COLLEGE_UNIV', text: 'Colegio, universidad u otra institución de educación superior'},
                    { value: 'SPORTS', text: 'Deportes organizados'},
                    { value: 'SHELTER', text: 'Shelter'},
                    { value: 'OTHER', text: 'Otro'}
                ],

                hcwRoleOptions: [
                    { value: '1', text: 'Médico'},
                    { value: '2', text: 'Enfermero'},
                    { value: '6', text: 'Asistente de enfermería certificado (CNA, por sus siglas en inglés)'},
                    { value: '7', text: 'Asistente de atención al paciente (PCA, por sus siglas en inglés)'},
                    { value: '3', text: 'Terapeuta respiratorio'},
                    { value: '4', text: 'Servicios ambientales (p. ej., personal de trabajos domésticos, de limpieza)'},
                    { value: '5', text: 'Other'},
                ],

                classTypeOptions: [
                    { value: 'NO', text: 'No asisto a clases'},
                    { value: 'ONLINE', text: 'En línea'},
                    { value: 'INPERSON', text: 'Presencial'},
                    { value: 'BOTH', text: 'Ambos'},
                ], 

                campusLivingOptions: [
                    { value: 'NO', text: 'Not living in on campus housing'},
                    { value: 'DORM', text: 'Residencia universitaria'},
                    { value: 'APARTMENT', text: 'Apartamentos en el campus'},
                    { value: 'GREEK', text: 'Fraternidad/Sororidad'},
                    { value: 'OTHER', text: 'Otras viviendas del campus'},
                ],

                campusActivityOptions: [
                    { value: 'NO', text: 'No pasé tiempo en el campus mientras era contagioso'},
                    { value: 'WORK', text: 'Trabajé en el campus'},
                    { value: 'FACILITIES', text: 'Utilicé las instalaciones del campus (i. e., biblioteca, gimnasio, cafetería, etc.)'},
                    { value: 'EVENTS', text: 'Asistí a algún evento, reunión o actividad patrocinada por la universidad'},
                    { value: 'HOUSING', text: 'Pasé tiempo en las residencias universitarias/fraternidades/viviendas del campus (incluidas las visitas a amigos en su campus o en otros)'},
                ],

                essentialItemOptions: [
                    { value: 'FOOD', text: 'Comida'},
                    { value: 'MED', text: 'Medicamentos'},
                    { value: 'PLACE', text: 'Un lugar seguro donde permanecer alejado de los demás'},
                    { value: 'OTHER', text: 'Otro'},
                ]
            }
        }
    },
}