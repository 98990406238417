<template>
    <b-container>

      <div class="home">
          <b-container fluid>
              <div id="pgTitle" tabindex="-1"><h1>{{pageTitle}}</h1></div>
              
              <div>
                <b-img src="/banner.jpg" fluid alt="Roll Up Your Sleeves Image"></b-img>
              </div>

              <b-row>
                <b-col>
                <p class="h5">
                  {{pageHeader}}
                </p>
                
                <p class="h5 mb-4">
                  To schedule an appointment, please call or click on the link below:
                  </p>
                </b-col>
              </b-row>

              <div>
                <b-card-group deck>
                  <b-card v-for="provider in filterProviders()" v-bind:key="provider.id"
                    :title="provider.name"
                    title-tag="h2"
                    :img-src="provider.image"
                    :img-alt="provider.name"
                    img-top
                    tag="article"
                    style="max-width: 25rem;"
                    class="mb-2">
              
                    <b-card-text>
                      {{provider.contact1}} <br/>
                      {{provider.contact2}}
                    </b-card-text>

                    <b-button class="mt-auto" variant="primary" @click="recordClick(provider.id, provider.url)">Go to {{provider.buttonText}} <b-icon ml-2 icon="box-arrow-right" aria-hidden="true"></b-icon></b-button>
                  </b-card>
                </b-card-group>
              </div>
          </b-container>
      </div>
    </b-container>

</template>

<script>
import axios from 'axios';
import axiosRetry from 'axios-retry';
import moment from 'moment'

export default {
  components: {
  },
  
  data: () => ({
      token: null, 
      campaign: 'NA',
      providers: [
        {
          "id": "allina",
          "name": "Allina Health",
          "url": "https://account.allinahealth.org",
          "image": "/allina.png",
          "contact1": "612-262-5533",
          "contact2": "",
          "buttonText": "Allina Health to sign into your account",
          "campaigns": ['NA', 'ALLINA']
        },
        {
          "id": "crowwing",
          "name": "Crow Wing County Public Health",
          "url": "https://www.crowwing.us/1632/COVID-19-Vaccine-Registration",
          "image": "/crowwing.jpg",
          "contact1": "218-824-1289",
          "contact2": "Michelle.moritz@crowwing.us",
          "buttonText": "Crow Wing County Vaccine Registration",
          "campaigns": ['CROW', 'B-CROW']
        },
         {
          "id": "washington",
          "name": "Washington County Public Health & Environment",
          "url": "https://www.co.washington.mn.us/3328/COVID-19-Vaccine-Clinics",
          "image": "/washington.jpg",
          "contact1": "651-430-6655",
          "contact2": "",
          "buttonText": "Washington Co Public Health",
          "campaigns": ['WASH', 'B-WASH']
        },
        {
          "id": "ramsey",
          "name": "Ramsey County Public Health",
          "url": "https://www.ramseycounty.us/covid-19-info/covid-19-vaccine#1",
          "image": "/ramsey.png",
          "contact1": "651-266-1190",
          "contact2": "",
          "buttonText": "Ramsey Co Public Health",
          "campaigns": ['RAMSEY']
        },
        {
          "id": "carver",
          "name": "Carver County Public Health",
          "url": "https://www.co.carver.mn.us/covid19vaccine",
          "image": "/CarverCountyICON.png",
          "contact1": "952-361-1559",
          "contact2": "",
          "buttonText": "Carver Co Public Health",
          "campaigns": ['CARVER']
        },
        {
          "id": "sherburne",
          "name": "Sherburne County Public Health",
          "url": "https://www.co.sherburne.mn.us/1202/COVID-19-vaccination-clinics",
          "image": "/sherburne-2.png",
          "contact1": "763-765-4156",
          "contact2": "",
          "buttonText": "Sherburne Co Public Health",
          "campaigns": ['SHERBURNE']
        },
        {
          "id": "freeborn",
          "name": "Freeborn County Public Health",
          "url": "http://www.co.freeborn.mn.us/139/Public-Health",
          "image": "/freeborne-2.png",
          "contact1": "507-377-5100",
          "contact2": "",
          "buttonText": "Freeborn Co Public Health",
          "campaigns": ['FREEBORN']
        },
        {
          "id": "sshs",
          "name": "Southside Community Health Services",
          "url": "https://www.southsidechs.org/contact-us",
          "image": "/sshs.png",
          "contact1": "raul.noriega@southsidechs.org",
          "contact2": "angelica.ortiz@southsidechs.org",
          "buttonText": "Southside Community Health Services",
          "campaigns": ['SSHS']
        },
        {
          "id": "B-sshs",
          "name": "Southside Community Health Services",
          "url": "https://www.southsidechs.org/contact-us",
          "image": "/sshs.png",
          "contact1": "raul.noriega@southsidechs.org",
          "contact2": "angelica.ortiz@southsidechs.org",
          "buttonText": "Southside Community Health Services",
          "campaigns": ['B-SSHS']
        },
        {
          "id": "hph",
          "name": "Horizon Public Health",
          "url": "https://horizonpublichealth.org/covid-19-vaccine-information/",
          "image": "/Horizon-Logo1.png",
          "contact1": "kelseyp@horizonph.org",
          "contact2": "",
          "buttonText": "Horizon Public Health",
          "campaigns": ['HPH','B-HPH']
        },
        {
          "id": "mhc",
          "name": "Minneapolis Health Clinic",
          "url": "https://minneapolishealthclinic.com/",
          "image": "/mhc.png",
          "contact1": "grace.totoe@minneapolishealthclinic.com",
          "contact2": "rachel.ebright@minneapolishealthclinic.com",
          "buttonText": "Minneapolis Health Clinic",
          "campaigns": ['MHC']
        },  
        {
          "id": "winona",
          "name": "Winona Health",
          "url": "https://z1-rpw.phreesia.net/ApptRequestForm.App/#/form/5add0564-e544-4630-9a7d-dde0383cb146",
          "image": "/winona.png",
          "contact1": "jender@winonahealth.org",
          "contact2": "",
          "buttonText": "Winona Health",
          "campaigns": ['WINONA', 'B-WINONA']
        },  
        {
          "id": "goodrich",
          "name": "Goodrich Pharmacy",
          "url": "https://www.goodrichpharmacy.com/covid-services/601-jacob-lane/vaccination",
          "image": "/gp.png",
          "contact1": "clmiddendorf@gmail.com",
          "contact2": "kailene.perry@gmail.com",
          "buttonText": "Goodrich Pharmacy",
          "campaigns": ['GP']
        },  
        {
          "id": "B-goodrich",
          "name": "Goodrich Pharmacy",
          "url": "https://www.goodrichpharmacy.com/covid-services/601-jacob-lane/vaccination",
          "image": "/gp.png",
          "contact1": "clmiddendorf@gmail.com",
          "contact2": "kailene.perry@gmail.com",
          "buttonText": "Goodrich Pharmacy",
          "campaigns": ['B-GP']
        },    
        {
          "id": "ottertail",
          "name": "Otter Tail Public Health",
          "url": "https://ottertailcountymn.us/content-page/covid-19-vaccine/",
          "image": "/otter.png",
          "contact1": "jmetzger@co.ottertail.mn.us",
          "contact2": "",
          "buttonText": "Otter Tail Public Health",
          "campaigns": ['OT']
        },         
        {
          "id": "peterson",
          "name": "Peterson Drug & Gifts Pharmacy",
          "url": "https://www.nucara.com/mountain-lake-mn",
          "image": "/NuCara_Full_New.png",
          "contact1": "eschroeder@nucara.com",
          "contact2": "",
          "buttonText": "Peterson Drug & Gifts Pharmacy",
          "campaigns": ['PP', 'B-PP']
        },   
        {
          "id": "stlouiscounty",
          "name": "St Louis County Public Health",
          "url": "http://stlouiscountymn.gov/vaccineregistration",
          "image": "/stlouis.png",
          "contact1": "albertk@stlouiscountymn.gov",
          "contact2": "vannormans@stlouiscountymn.gov",
          "buttonText": "St Louis County Public Health",
          "campaigns": ['SLC']
        },     
        {
          "id": "pinecounty",
          "name": "Pine County Public Health",
          "url": "https://www.co.pine.mn.us/departments/health_and_human_services/coronavirus_(covid-19)_resources/vaccine_interest_form.php",
          "image": "/pine.png",
          "contact1": "Samantha.Lo@co.pine.mn.us",
          "contact2": "jessica.fehlen@co.pine.mn.us",
          "buttonText": "Pine County Public Health",
          "campaigns": ['PC']
        },  
        {
          "id": "B-pinecounty",
          "name": "Pine County Public Health",
          "url": "https://www.co.pine.mn.us/departments/health_and_human_services/coronavirus_(covid-19)_resources/vaccine_interest_form.php",
          "image": "/pine.png",
          "contact1": "Samantha.Lo@co.pine.mn.us",
          "contact2": "jessica.fehlen@co.pine.mn.us",
          "buttonText": "Pine County Public Health",
          "campaigns": ['B-PC']
        },       
        {
          "id": "kemper",
          "name": "Kemper Corner Drug Pharmacy",
          "url": "https://hipaa.jotform.com/210394949590163",
          "image": "/kemper.png",
          "contact1": "elkriverpharmacy@gmail.com",
          "contact2": "",
          "buttonText": "Kemper Corner Drug Pharmacy",
          "campaigns": ['KCDP']
        },     
        {
          "id": "icns",
          "name": "Inter-County Nursing Service",
          "url": "http://www.intercountynursingservice.com/?page_id=1576",
          "image": "/icns.jpg",
          "contact1": "cjonkka@co.pennington.mn.us",
          "contact2": "krjore@co.pennington.mn.us",
          "buttonText": "Inter-County Nursing Service",
          "campaigns": ['ICNS']
        },       
        {
          "id": "cub",
          "name": "Cub Pharmacy",
          "url": "https://www.cub.com/pharmacy.html",
          "image": "/cub.jpg",
          "contact1": "erin.k.suomala@cub.com",
          "contact2": "Aaron.W.Sapp@cub.com, Michael.Heaton@cub.com",
          "buttonText": "Cub Pharmacy",
          "campaigns": ['CUB']
        },   
        {
          "id": "B-cub",
          "name": "Cub Pharmacy",
          "url": "https://www.cub.com/pharmacy.html",
          "image": "/cub.jpg",
          "contact1": "erin.k.suomala@cub.com",
          "contact2": "Aaron.W.Sapp@cub.com, Michael.Heaton@cub.com",
          "buttonText": "Cub Pharmacy",
          "campaigns": ['B-CUB']
        },      
        {
          "id": "komg",
          "name": "Keaveny and Odam Medical Group",
          "url": "https://www.keavenydrug.com/",
          "image": "/keaveny.png",
          "contact1": "deborah.keaveny@gmail.com",
          "contact2": "",
          "buttonText": "Keaveny and Odam Medical Group",
          "campaigns": ['KOMG','B-KOMG']
        },       
        {
          "id": "nm",
          "name": "North Memorial",
          "url": "https://vaccineconnector.mn.gov/en-US/",
          "image": "/mvc.jpg",
          "contact1": "paul.krogh@northmemorial.com",
          "contact2": "",
          "buttonText": "MN Vaccine Connector",
          "campaigns": ['NM']
        },    
        {
          "id": "scph",
          "name": "Steele County Public Health",
          "url": "https://www.co.steele.mn.us",
          "image": "/steele.jpg",
          "contact1": "(507) 444-7650",
          "contact2": "",
          "buttonText": "Steele County Public Health",
          "campaigns": ['SCPH', 'B-SCPH']
        },          
        {
          "id": "casey",
          "name": "Casey Drug",
          "url": "https://www.caseydrugpharmacy.com/",
          "image": "/caseydrug.png",
          "contact1": "alidaerin@gmail.com",
          "contact2": "",
          "buttonText": "Casey Drug",
          "campaigns": ['B-CD']
        },   
        {
          "id": "brown",
          "name": "Brown County Public Health",
          "url": "https://PublicHealthImmunizations.as.me/COVID-19",
          "image": "/brown.jpg",
          "contact1": "(507) 233-6820",
          "contact2": "",
          "buttonText": "Brown County Public Health",
          "campaigns": ['BROWN']
        },  
        {
          "id": "b-brown",
          "name": "Brown County Public Health",
          "url": "https://PublicHealthImmunizations.as.me/BoosterClinic",
          "image": "/brown.jpg",
          "contact1": "(507) 233-6820",
          "contact2": "",
          "buttonText": "Brown County Public Health",
          "campaigns": ['B-BROWN']
        },  
        {
          "id": "b-spec",
          "name": "Specialysts",
          "url": "https://vaccineconnector.mn.gov/en-US/",
          "image": "/mvc.jpg",
          "contact1": "",
          "contact2": "",
          "buttonText": "Specialysts",
          "campaigns": ['B-SPEC']
        },  
      ],
  }),
  
  created() {
      this.token = this.$route.params.token
      this.recordClick(null, null)

      if (this.$route.query.c) {
        this.campaign = this.$route.query.c
      }
  },

  computed: {
      pageTitle() {
           if (this.campaign.startsWith("B-")) {
            return "COVID-19 Vaccine Booster Dose Reminder"
           } else {
             return "COVID-19 Vaccine Second Dose Reminder"
           }
       },
       pageHeader() {
         if (this.campaign.startsWith("B-")) {
            return "Thank you for completing your COVID-19 vaccination series. You or someone in your household are now eligible to receive a booster dose of COVID-19 vaccine."
         } else {
           return "Thank you for getting your first COVID-19 vaccination. We would like to remind you to be sure to get your second dose as soon as possible."
         }
       }
  },

  methods: {
    filterProviders() {
      if (this.$route.query.c) {
        this.campaign = this.$route.query.c
      }

      let _this = this
      let lea = this.providers.filter(function(obj) {
        if (obj['campaigns'].includes(_this.campaign)) {
          return obj;
        }
      })
      return lea
    },

    recordClick(destination, url) {
      let payload = {
        trackingId: this.token ? this.token : '',
        destination: destination
      }

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/click`, payload)
        .then(response => {
        })
	
      if (url) {
        window.location.href = url
      }
    },
  },

  mounted() {
     let payload = {
        trackingId: this.token ? this.token : ''
      }

      axios.put(`${process.env.VUE_APP_API_BASE_URL}/click`, payload)
        .then(response => {
          this.campaign = response.data;
        })
  },
  
}
</script>

<style>
.card-img-top {
  padding: 20px;
}
    
</style>