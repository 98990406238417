import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import Vuelidate from 'vuelidate'
import VueLogger from 'vuejs-logger';
import errorResponseHandler from "@/services/errorResponseHandler";
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

import Axios from '@/plugins/axios-plugin';

import i18n from './i18n'
import moment from 'moment'

Vue.config.productionTip = false
Vue.use(Vuelidate)

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY')
  }
});

Vue.filter('formatTime', function(value) {
  if (value) {
    return moment(String(value)).format('h:mm a')
  }
});

const options = {
  isEnabled: true,
  logLevel : Vue.config.productionTip  ? 'error' : 'debug',
  stringifyArguments : false,
  showLogLevel : true,
  showMethodName : true,
  separator: '|',
  showConsoleColors: true
};

Vue.use(VueLogger, options);
Vue.use(Axios)

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
