import Vue from 'vue'
import Router from 'vue-router'
import Index from '@/views/Index.vue'

Vue.use(Router)

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/timeout',
      name: 'timeout',
      meta: {
        requiresAuth: false
      },
      component: () => import(/* webpackChunkName: "timeout" */ './views/Timeout.vue')
    },

    {
      path: '/unauthorized',
      name: 'unauthorized',
      meta: {
        requiresAuth: false
      },
      component: () => import(/* webpackChunkName: "unauthorized" */ './views/Unauthorized.vue')
    },

    {
      path: '/:token',
      name: 'index',
      meta: {
        requiresAuth: false
      },
      component: Index
    },
    
    {
      path: '*', 
      component: Index
    }
  ]
})

export default router