import i18n from '@/i18n'
import { commonOptions_es } from '@/mixins/commonOptions_es.js'
import { commonOptions_hmn } from '@/mixins/commonOptions_hmn.js'
import { commonOptions_so } from '@/mixins/commonOptions_so.js'

export const commonOptions = {

    mixins: [
        commonOptions_es,
        commonOptions_hmn,
        commonOptions_so,
     ],

    data() {
        return { 

            languageOptions: [
                { value: 'en', text: 'English' },
                { value: 'es', text: 'Español' },
                { value: 'hmn', text: 'Hmong' },
                { value: 'so', text: 'Soomaali' },
            ],

            tribeOptions: [
                { value:"1", text:"Bois Forte Band of Chippewa" },
                { value:"2", text:"Fond Du Lac Reservation" },
                { value:"3", text:"Grand Portage Band of Chippewa Indians" },
                { value:"4", text:"Leech Lake Band of Ojibwe" },
                { value:"5", text:"Lower Sioux Indian Community" },
                { value:"6", text:"Mille Lacs Band of Ojibwe" },
                { value:"7", text:"Prairie Island Indian Community" },
                { value:"8", text:"Red Lake Band of Chippewa Indians" },
                { value:"9", text:"Shakopee Mdewakanton Sioux (Dakota) Community" },
                { value:"10", text:"Upper Sioux Community" },
                { value:"11", text:"White Earth Reservation" },
                { value:"22", text:"Multiple Tribes" },
                { value:"88", text:"Other/Out of State Tribe" },
                { value:"99", text:"Unknown" },
                { value:"77", text:"Not enrolled" },
            ],

            stateOptions: [
                { value:"AK", text:"Alaska" },
                { value:"AL", text:"Alabama" },
                { value:"AR", text:"Arkansas" },
                { value:"AZ", text:"Arizona" },
                { value:"CA", text:"California" },
                { value:"CO", text:"Colorado" },
                { value:"CT", text:"Connecticut" },
                { value:"DC", text:"District of Columbia" },
                { value:"DE", text:"Delaware" },
                { value:"FL", text:"Florida" },
                { value:"GA", text:"Georgia" },
                { value:"HI", text:"Hawaii" },
                { value:"IA", text:"Iowa" },
                { value:"ID", text:"Idaho" },
                { value:"IL", text:"Illinois" },
                { value:"IN", text:"Indiana" },
                { value:"KS", text:"Kansas" },
                { value:"KY", text:"Kentucky" },
                { value:"LA", text:"Louisiana" },
                { value:"MA", text:"Massachusetts" },
                { value:"MD", text:"Maryland" },
                { value:"ME", text:"Maine" },
                { value:"MI", text:"Michigan" },
                { value:"MN", text:"Minnesota" },
                { value:"MO", text:"Missouri" },
                { value:"MS", text:"Mississippi" },
                { value:"MT", text:"Montana" },
                { value:"NC", text:"North Carolina" },
                { value:"ND", text:"North Dakota" },
                { value:"NE", text:"Nebraska" },
                { value:"NH", text:"New Hampshire" },
                { value:"NJ", text:"New Jersey" },
                { value:"NM", text:"New Mexico" },
                { value:"NV", text:"Nevada" },
                { value:"NY", text:"New York" },
                { value:"OH", text:"Ohio" },
                { value:"OK", text:"Oklahoma" },
                { value:"OR", text:"Oregon" },
                { value:"PA", text:"Pennsylvania" },
                { value:"RI", text:"Rhode Island" },
                { value:"SC", text:"South Carolina" },
                { value:"SD", text:"South Dakota" },
                { value:"TN", text:"Tennessee" },
                { value:"TX", text:"Texas" },
                { value:"UT", text:"Utah" },
                { value:"VA", text:"Virginia" },
                { value:"VT", text:"Vermont" },
                { value:"WA", text:"Washington" },
                { value:"WI", text:"Wisconsin" },
                { value:"WV", text:"West Virginia" },
                { value:"WY", text:"Wyoming" },
            ],

            countyOptions: [
                { value:"Aitkin County", text:"Aitkin" },
                { value:"Anoka County", text:"Anoka" },
                { value:"Blue Earth County", text:"Blue Earth" },
                { value:"Becker County", text:"Becker" },
                { value:"Beltrami County", text:"Beltrami" },
                { value:"Benton County", text:"Benton" },
                { value:"Brown County", text:"Brown" },
                { value:"Big Stone County", text:"Big Stone" },
                { value:"Chippewa County", text:"Chippewa" },
                { value:"Chisago County", text:"Chisago" },
                { value:"Clay County", text:"Clay" },
                { value:"Cook County", text:"Cook" },
                { value:"Cottonwood County", text:"Cottonwood" },
                { value:"Cass County", text:"Cass" },
                { value:"Carlton County", text:"Carlton" },
                { value:"Clearwater County", text:"Clearwater" },
                { value:"Carver County", text:"Carver" },
                { value:"Crow Wing County", text:"Crow Wing" },
                { value:"Dakota County", text:"Dakota" },
                { value:"Dodge County", text:"Dodge" },
                { value:"Douglas County", text:"Douglas" },
                { value:"Faribault County", text:"Faribault" },
                { value:"Fillmore County", text:"Fillmore" },
                { value:"Freeborn County", text:"Freeborn" },
                { value:"Goodhue County", text:"Goodhue" },
                { value:"Grant County", text:"Grant" },
                { value:"Hennepin County", text:"Hennepin" },
                { value:"Houston County", text:"Houston" },
                { value:"Hubbard County", text:"Hubbard" },
                { value:"Isanti County", text:"Isanti" },
                { value:"Itasca County", text:"Itasca" },
                { value:"Jackson County", text:"Jackson" },
                { value:"Kanabec County", text:"Kanabec" },
                { value:"Kandiyohi County", text:"Kandiyohi" },
                { value:"Kittson County", text:"Kittson" },
                { value:"Koochiching County", text:"Koochiching" },
                { value:"Lincoln County", text:"Lincoln" },
                { value:"Lake County", text:"Lake" },
                { value:"Lake of the Woods County", text:"Lake of the Woods" },
                { value:"Lac qui Parle County", text:"Lac qui Parle" },
                { value:"Le Sueur County", text:"Le Sueur" },
                { value:"Lyon County", text:"Lyon" },
                { value:"McLeod County", text:"McLeod" },
                { value:"Meeker County", text:"Meeker" },
                { value:"Mahnomen County", text:"Mahnomen" },
                { value:"Mille Lacs County", text:"Mille Lacs" },
                { value:"Morrison County", text:"Morrison" },
                { value:"Mower County", text:"Mower" },
                { value:"Marshall County", text:"Marshall" },
                { value:"Martin County", text:"Martin" },
                { value:"Murray County", text:"Murray" },
                { value:"Nicollet County", text:"Nicollet" },
                { value:"Nobles County", text:"Nobles" },
                { value:"Norman County", text:"Norman" },
                { value:"Olmsted County", text:"Olmsted" },
                { value:"Otter Tail County", text:"Otter Tail" },
                { value:"Pennington County", text:"Pennington" },
                { value:"Pine County", text:"Pine" },
                { value:"Pipestone County", text:"Pipestone" },
                { value:"Polk County", text:"Polk" },
                { value:"Pope County", text:"Pope" },
                { value:"Ramsey County", text:"Ramsey" },
                { value:"Redwood County", text:"Redwood" },
                { value:"Renville County", text:"Renville" },
                { value:"Rice County", text:"Rice" },
                { value:"Red Lake County", text:"Red Lake" },                
                { value:"Rock County", text:"Rock" },
                { value:"Roseau County", text:"Roseau" },
                { value:"Scott County", text:"Scott" },
                { value:"Sherburne County", text:"Sherburne" },
                { value:"Sibley County", text:"Sibley" },
                { value:"St. Louis County", text:"St. Louis" },
                { value:"Stearns County", text:"Stearns" },
                { value:"Steele County", text:"Steele" },
                { value:"Stevens County", text:"Stevens" },
                { value:"Swift County", text:"Swift" },
                { value:"Todd County", text:"Todd" },
                { value:"Traverse County", text:"Traverse" },
                { value:"Wabasha County", text:"Wabasha" },
                { value:"Wadena County", text:"Wadena" },
                { value:"Wilkin County", text:"Wilkin" },
                { value:"Winona County", text:"Winona" },
                { value:"Wright County", text:"Wright" },
                { value:"Waseca County", text:"Waseca" },
                { value:"Washington County", text:"Washington" },
                { value:"Watonwan County", text:"Watonwan" },
                { value:"Yellow Medicine County", text:"Yellow Medicine" },

            ],

            languagePrefOptions: [
                { value: "English American", text: "English American" },
                { value: "Unknown", text: "Unknown" },
                { value: "Other", text: "Other" },
                { value: "Abkhazian", text: "Abkhazian" },
                { value: "Afar", text: "Afar" },
                { value: "Afrikaans", text: "Afrikaans" },
                { value: "Albanian", text: "Albanian" },
                { value: "American Sign Language", text: "American Sign Language" },
                { value: "Amharic", text: "Amharic" },
                { value: "Arabic", text: "Arabic" },
                { value: "Armenian", text: "Armenian" },
                { value: "Assamese", text: "Assamese" },
                { value: "Aymara", text: "Aymara" },
                { value: "Azerbaijani", text: "Azerbaijani" },
                { value: "Bangla", text: "Bangla" },
                { value: "Bashkir", text: "Bashkir" },
                { value: "Basque", text: "Basque" },
                { value: "Bhutani", text: "Bhutani" },
                { value: "Bihari", text: "Bihari" },
                { value: "Bislama", text: "Bislama" },
                { value: "Bosnian", text: "Bosnian" },
                { value: "Breton", text: "Breton" },
                { value: "Bulgarian", text: "Bulgarian" },
                { value: "Burmese", text: "Burmese" },
                { value: "Byelorussian", text: "Byelorussian" },
                { value: "Cambodian", text: "Cambodian" },
                { value: "Cantonese", text: "Cantonese" },
                { value: "Catalan", text: "Catalan" },
                { value: "Chinese", text: "Chinese" },
                { value: "Corsican", text: "Corsican" },
                { value: "Creole and pidgins (Other)", text: "Creole and pidgins (Other)" },
                { value: "Creole and pidgins, English-based (Other)", text: "Creole and pidgins, English-based (Other)" },
                { value: "Creole and pidgins, French-based (Other)", text: "Creole and pidgins, French-based (Other)" },
                { value: "Creole and pidgins, Portuguese-based (Other)", text: "Creole and pidgins, Portuguese-based (Other)" },
                { value: "Croatian", text: "Croatian" },
                { value: "Czech", text: "Czech" },
                { value: "Danish", text: "Danish" },
                { value: "Dutch", text: "Dutch" },
                { value: "Esperanto", text: "Esperanto" },
                { value: "Estonian", text: "Estonian" },
                { value: "Faeroese", text: "Faeroese" },
                { value: "Fiji", text: "Fiji" },
                { value: "Filipino", text: "Filipino" },
                { value: "Finnish", text: "Finnish" },
                { value: "French", text: "French" },
                { value: "Frisian", text: "Frisian" },
                { value: "Gaelic Scots Gaelic", text: "Gaelic Scots Gaelic" },
                { value: "Galician", text: "Galician" },
                { value: "Georgian", text: "Georgian" },
                { value: "German", text: "German" },
                { value: "Greek", text: "Greek" },
                { value: "Greenlandic", text: "Greenlandic" },
                { value: "Guarani", text: "Guarani" },
                { value: "Gujarati", text: "Gujarati" },
                { value: "Haida", text: "Haida" },
                { value: "Hausa", text: "Hausa" },
                { value: "Hebrew", text: "Hebrew" },
                { value: "Hindi", text: "Hindi" },
                { value: "Hmong", text: "Hmong" },
                { value: "Hungarian", text: "Hungarian" },
                { value: "Icelandic", text: "Icelandic" },
                { value: "Interlingua", text: "Interlingua" },
                { value: "Interlingue", text: "Interlingue" },
                { value: "Indonesian", text: "Indonesian" },
                { value: "Inupiak", text: "Inupiak" },
                { value: "Irish", text: "Irish" },
                { value: "Italian", text: "Italian" },
                { value: "Japanese", text: "Japanese" },
                { value: "Javanese", text: "Javanese" },
                { value: "Kannada", text: "Kannada" },
                { value: "Karen", text: "Karen" },
                { value: "Kashmiri", text: "Kashmiri" },
                { value: "Kazakh", text: "Kazakh" },
                { value: "Kinyarwanda", text: "Kinyarwanda" },
                { value: "Kirghiz", text: "Kirghiz" },
                { value: "Kirundi", text: "Kirundi" },
                { value: "Korean", text: "Korean" },
                { value: "Kurdish", text: "Kurdish" },
                { value: "Laothian", text: "Laothian" },
                { value: "Latin", text: "Latin" },
                { value: "Latvian Lettish", text: "Latvian Lettish" },
                { value: "Lingala", text: "Lingala" },
                { value: "Lithuanian", text: "Lithuanian" },
                { value: "Mandar", text: "Mandar" },
                { value: "Macedonian", text: "Macedonian" },
                { value: "Malagasy", text: "Malagasy" },
                { value: "Malay", text: "Malay" },
                { value: "Malayalam", text: "Malayalam" },
                { value: "Maltese", text: "Maltese" },
                { value: "Maori", text: "Maori" },
                { value: "Marathi", text: "Marathi" },
                { value: "Moldavian", text: "Moldavian" },
                { value: "Mongolian", text: "Mongolian" },
                { value: "Nauru", text: "Nauru" },
                { value: "Nepali", text: "Nepali" },
                { value: "Norwegian", text: "Norwegian" },
                { value: "Occitan", text: "Occitan" },
                { value: "Oriya", text: "Oriya" },
                { value: "Oromo Afan", text: "Oromo Afan" },
                { value: "Pashto Pushto", text: "Pashto Pushto" },
                { value: "Persian", text: "Persian" },
                { value: "Polish", text: "Polish" },
                { value: "Portuguese", text: "Portuguese" },
                { value: "Punjabi", text: "Punjabi" },
                { value: "Quechua", text: "Quechua" },
                { value: "Rhaeto-Romance", text: "Rhaeto-Romance" },
                { value: "Romanian", text: "Romanian" },
                { value: "Russian", text: "Russian" },
                { value: "Sami", text: "Sami" },
                { value: "Samoan", text: "Samoan" },
                { value: "Sangro", text: "Sangro" },
                { value: "Sanskrit", text: "Sanskrit" },
                { value: "Serbian", text: "Serbian" },
                { value: "Serbo-Croatian", text: "Serbo-Croatian" },
                { value: "Sesotho", text: "Sesotho" },
                { value: "Setswana", text: "Setswana" },
                { value: "Shona", text: "Shona" },
                { value: "Sindhi", text: "Sindhi" },
                { value: "Singhalese", text: "Singhalese" },
                { value: "Siswati", text: "Siswati" },
                { value: "Slovak", text: "Slovak" },
                { value: "Slovenian", text: "Slovenian" },
                { value: "Somali", text: "Somali" },
                { value: "Spanish", text: "Spanish" },
                { value: "Sudanese", text: "Sudanese" },
                { value: "Swahili", text: "Swahili" },
                { value: "Swedish", text: "Swedish" },
                { value: "Tagalog", text: "Tagalog" },
                { value: "Tai (Other)", text: "Tai (Other)" },
                { value: "Tajik", text: "Tajik" },
                { value: "Tamil", text: "Tamil" },
                { value: "Tatar", text: "Tatar" },
                { value: "Tegulu", text: "Tegulu" },
                { value: "Thai", text: "Thai" },
                { value: "Tibetan", text: "Tibetan" },
                { value: "Tigrinya", text: "Tigrinya" },
                { value: "Tonga", text: "Tonga" },
                { value: "Tsonga", text: "Tsonga" },
                { value: "Turkish", text: "Turkish" },
                { value: "Turkmen", text: "Turkmen" },
                { value: "Twi", text: "Twi" },
                { value: "Ukrainian", text: "Ukrainian" },
                { value: "Urdu", text: "Urdu" },
                { value: "Uzbek", text: "Uzbek" },
                { value: "Vietnamese", text: "Vietnamese" },
                { value: "Volapuk", text: "Volapuk" },
                { value: "Welsh", text: "Welsh" },
                { value: "Wolof", text: "Wolof" },
                { value: "Xhosa", text: "Xhosa" },
                { value: "Yiddish", text: "Yiddish" },
                { value: "Yoruba", text: "Yoruba" },
                { value: "Zulu", text: "Zulu" }
            ],

               en: {
                sexOptions: [
                    { value: 'M', text: 'Male' },
                    { value: 'F', text: 'Female' },
                    { value: 'O', text: 'Transgender' },
                    { value: 'O', text: 'Non-Binary' },
                    { value: 'U', text: 'Prefer Not to Say' },
                ],

                lgbtOptions: [
                    { value: '1', text: 'Straight' },
                    { value: '2', text: 'Gay or Lesbian' },
                    { value: '4', text: 'Bisexual' },
                    { value: '5', text: 'I don\'t know' },
                    { value: '6', text: 'Something else' },
                    { value: '9', text: 'Prefer not to say' },
                ],

                raceOptions: [
                    { value: 'W', text: 'White' },
                    { value: 'B', text: 'Black' },
                    { value: 'A', text: 'Asian' },
                    { value: 'P', text: 'Native Hawaiian/Pacific Islander' },
                    { value: 'I', text: 'American Indian/Alaska Native' },
                    { value: 'O', text: 'Other' },
                    { value: 'U', text: 'Unknown' },
                ],

                livingOptions: [
                    //{ value: '1', text: 'Private residence' },
                    { value: '11', text: 'Hotel/Motel' },
                    { value: '2', text: 'Long-term care facility, Rehabilitation facility or Assisted living facility' },
                    { value: '9', text: 'Group home or behavioral health facility' },
                    { value: '5', text: 'A shelter for people experiencing homelessness' },
                    { value: '12', text: 'Currently not sheltered and experiencing homelessness' },
                    { value: '6', text: 'Jail/Prison' },
                    { value: '7', text: 'College/University/Camp dormitory' },
                    { value: '10', text: 'Work dormitory' },
                    { value: '8', text: 'Other' },
                ],

                contactSettingOptions: [
                    { value: 'H', text: 'Health care facility' },
                    { value: 'N', text: 'Nursing home, long-term care facility, rehab facility, or a similar type facility' },
                    { value: 'G', text: 'Group home or similar type facility' },
                    { value: 'C', text: 'Correctional facility' },
                    { value: 'S', text: 'Shelter for people experiencing homelessness' }
                ],

                eventOptions: [
                    { value: '10', text: 'Restaurants or bars' },
                    { value: '11', text: 'Salons or spas' },
                    { value: '12', text: 'Gyms or fitness centers' },
                    { value: '13', text: 'Sports (participate in or attend events)' },
                    { value: '1', text: 'Religious service' },
                    { value: '2', text: 'Festival or fair' },
                    { value: '3', text: 'Sporting event' },
                    { value: '4', text: 'Family/social gatherings or parties' },
                    { value: '5', text: 'Concert' },
                    { value: '6', text: 'Wedding' },
                    { value: '7', text: 'Funeral/wake' },
                    { value: '8', text: 'Block party' },
                    { value: '9', text: 'Other' }
                ],
                
                yesNoOptions: [
                    { value: 'Y', text: 'Yes' },
                    { value: 'N', text: 'No' },            
                ],

                symptomYesNoOptions: [
                    { value: 'Y', text: 'Yes, I have had symptoms' },
                    { value: 'N', text: 'No, I have not had any symptoms' },            
                ],

                yesNoUnknownOptions: [
                    { value: 'Y', text: 'Yes' },
                    { value: 'N', text: 'No' },
                    { value: 'U', text: "Don't Know" },            
                ],

                yesNoUnknownNaOptions: [
                    { value: 'Y', text: 'Yes' },
                    { value: 'N', text: 'No' },
                    { value: 'U', text: "Don't Know" },
                    { value: 'X', text: "Not Applicable" },            
                ],

                covid19Symptoms: [
                    'Fever',
                    'Cough',
                    'Shortness of breath',
                    'Loss of sense of taste or smell',
                    'Fatigue',
                    'Headache',
                    'Muscle aches',
                    'Diarrhea',
                    'Nausea or vomiting'
                ],

                workplaceSettings: [
                    { value: 'HEALTHCARE', text: 'Health care setting such as a hospital or clinic' },
                    { value: 'LTC', text: 'Long-term care, assisted living, or nursing home' },
                    { value: 'RES_TREAT', text: 'Substance abuse and treatment inpatient program' },
                    { value: 'GROUP_HOME', text: 'Group home'},
                    { value: 'CORRECTIONS', text: 'Correctional facility like a prison or jail'},
                    { value: 'CHILDCARE', text: 'Child care center or in-home child care'},
                    { value: 'K12', text: 'School'},
                    { value: 'COLLEGE_UNIV', text: 'College, university or other institution of higher education'},
                    { value: 'SPORTS', text: 'Organized Sports'},
                    { value: 'SHELTER', text: 'Shelter'},
                    { value: 'OTHER', text: 'Other'}
                ],

                hcwRoleOptions: [
                    { value: '1', text: 'Physician'},
                    { value: '2', text: 'Nurse'},
                    { value: '6', text: 'CNA (Certified Nursing Assistant)'},
                    { value: '7', text: 'PCA (Patient Care Assistant)'},
                    { value: '3', text: 'Respiratory Therapist'},
                    { value: '4', text: 'Environmental Services (e.g., housekeeping, cleaning staff)'},
                    { value: '5', text: 'Other'},
                ],

                classTypeOptions: [
                    { value: 'NO', text: 'I do not attend classes'},
                    { value: 'ONLINE', text: 'Online'},
                    { value: 'INPERSON', text: 'In Person'},
                    { value: 'BOTH', text: 'Both'},
                ], 

                campusLivingOptions: [
                    { value: 'NO', text: 'Not living in on campus housing'},
                    { value: 'DORM', text: 'Dorm'},
                    { value: 'APARTMENT', text: 'Campus apartments'},
                    { value: 'GREEK', text: 'Fraternity/Sorority'},
                    { value: 'OTHER', text: 'Other campus housing'},
                ],

                campusActivityOptions: [
                    { value: 'NO', text: 'Did not spend time on campus while infectious'},
                    { value: 'WORK', text: 'Worked on campus'},
                    { value: 'FACILITIES', text: 'Used any campus facilities (i.e. library, gym, cafeteria, etc.'},
                    { value: 'EVENTS', text: 'Attended any university sponsored events, meetings, or activities'},
                    { value: 'HOUSING', text: 'Spent time on campus dorms/fraternities/in campus housing (including visiting friends at your or other campuses)'},
                ],

                essentialItemOptions: [
                    { value: 'FOOD', text: 'Food'},
                    { value: 'MED', text: 'Medicine'},
                    { value: 'PLACE', text: 'Safe place to stay away from others'},
                    { value: 'OTHER', text: 'Other'},
                ]

            }
        }
    },

    computed: {
        sexOptions() {
            switch (this.$root.$i18n.locale) {
                case 'en': return this.en.sexOptions;
                case 'es': return this.es.sexOptions;
                case 'hmn': return this.hmn.sexOptions;
                case 'so': return this.so.sexOptions;
            }
        },
        lgbtOptions() {
            switch (this.$root.$i18n.locale) {
                case 'en': return this.en.lgbtOptions;
                case 'es': return this.es.lgbtOptions;
                case 'hmn': return this.hmn.lgbtOptions;
                case 'so': return this.so.lgbtOptions;
            }
        },
        raceOptions() { 
            switch (this.$root.$i18n.locale) {
                case 'en': return this.en.raceOptions;
                case 'es': return this.es.raceOptions;
                case 'hmn': return this.hmn.raceOptions;
                case 'so': return this.so.raceOptions;
            }
        },

        livingOptions() { 
            switch (this.$root.$i18n.locale) {
                case 'en': return this.en.livingOptions;
                case 'es': return this.es.livingOptions;
                case 'hmn': return this.hmn.livingOptions;
                case 'so': return this.so.livingOptions;
            }
        },

        contactSettingOptions() { 
            switch (this.$root.$i18n.locale) {
                case 'en': return this.en.contactSettingOptions;
                case 'es': return this.es.contactSettingOptions;
                case 'hmn': return this.hmn.contactSettingOptions;
                case 'so': return this.so.contactSettingOptions;
            }
        },

        eventOptions() { 
            switch (this.$root.$i18n.locale) {
                case 'en': return this.en.eventOptions;
                case 'es': return this.es.eventOptions;
                case 'hmn': return this.hmn.eventOptions;
                case 'so': return this.so.eventOptions;
            }
        },

        yesNoOptions() { 
            switch (this.$root.$i18n.locale) {
                case 'en': return this.en.yesNoOptions;
                case 'es': return this.es.yesNoOptions;
                case 'hmn': return this.hmn.yesNoOptions;
                case 'so': return this.so.yesNoOptions;
            }
        },

        symptomYesNoOptions() { 
            switch (this.$root.$i18n.locale) {
                case 'en': return this.en.symptomYesNoOptions;
                case 'es': return this.es.symptomYesNoOptions;
                case 'hmn': return this.hmn.symptomYesNoOptions;
                case 'so': return this.so.symptomYesNoOptions;
            }
        },

        yesNoUnknownOptions() { 
            switch (this.$root.$i18n.locale) {
                case 'en': return this.en.yesNoUnknownOptions;
                case 'es': return this.es.yesNoUnknownOptions;
                case 'hmn': return this.hmn.yesNoUnknownOptions;
                case 'so': return this.so.yesNoUnknownOptions;
            }
        },

        yesNoUnknownNaOptions() { 
            switch (this.$root.$i18n.locale) {
                case 'en': return this.en.yesNoUnknownNaOptions;
                case 'es': return this.es.yesNoUnknownNaOptions;
                case 'hmn': return this.hmn.yesNoUnknownNaOptions;
                case 'so': return this.so.yesNoUnknownNaOptions;
            }
        },

        covid19Symptoms() { 
            switch (this.$root.$i18n.locale) {
                case 'en': return this.en.covid19Symptoms;
                case 'es': return this.es.covid19Symptoms;
                case 'hmn': return this.hmn.covid19Symptoms;
                case 'so': return this.so.covid19Symptoms;
            }
        },

        workplaceSettings() { 
            switch (this.$root.$i18n.locale) {
                case 'en': return this.en.workplaceSettings;
                case 'es': return this.es.workplaceSettings;
                case 'hmn': return this.hmn.workplaceSettings;
                case 'so': return this.so.workplaceSettings;
            }
        },

        hcwRoleOptions() {
            switch (this.$root.$i18n.locale) {
                case 'en': return this.en.hcwRoleOptions;
                case 'es': return this.es.hcwRoleOptions;
                case 'hmn': return this.hmn.hcwRoleOptions;
                case 'so': return this.so.hcwRoleOptions;
            }
        },

        classTypeOptions() {
            switch (this.$root.$i18n.locale) {
                case 'en': return this.en.classTypeOptions;
                case 'es': return this.es.classTypeOptions;
                case 'hmn': return this.hmn.classTypeOptions;
                case 'so': return this.so.classTypeOptions;
            }
        },

        campusLivingOptions() {
            switch (this.$root.$i18n.locale) {
                case 'en': return this.en.campusLivingOptions;
                case 'es': return this.es.campusLivingOptions;
                case 'hmn': return this.hmn.campusLivingOptions;
                case 'so': return this.so.campusLivingOptions;
            }
        },

        campusActivityOptions() {
            switch (this.$root.$i18n.locale) {
                case 'en': return this.en.campusActivityOptions;
                case 'es': return this.es.campusActivityOptions;
                case 'hmn': return this.hmn.campusActivityOptions;
                case 'so': return this.so.campusActivityOptions;
            }
        },

        essentialItemOptions() {
            switch (this.$root.$i18n.locale) {
                case 'en': return this.en.essentialItemOptions;
                case 'es': return this.es.essentialItemOptions;
                case 'hmn': return this.hmn.essentialItemOptions;
                case 'so': return this.so.essentialItemOptions;
            }
        },

    }
}